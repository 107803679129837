@font-face {
    font-family: Gotham-Light;
    src: url('../fonts/gotham/Gotham-Light.otf');
}
@font-face {
    font-family: Montserrat-Thin;
    src: url('../fonts/montserrat/Montserrat-Thin.ttf');
}
@font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: Montserrat-Medium;
    src: url('../fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
    font-family: Montserrat-Italic;
    src: url('../fonts/montserrat/Montserrat-Italic.ttf');
}

@font-face {
    font-family: Montserrat-Black;
    src: url('../fonts/montserrat/Montserrat-Black.ttf');
}



body, html {
    height: 100%;
    font-family: Montserrat-Regular, sans-serif;
    font-weight: 400;
}

h1 {
    font-family: Montserrat-Regular, sans-serif;
}
